import React, {useCallback, useMemo, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import {LanguagesKeys} from '../../context/header/header-context';

import './style.scss';
import {Button} from "../../components";

const jsonKeyByLangCode = Object.freeze({
    [LanguagesKeys.en]: 'englishJson',
    [LanguagesKeys.th]: 'thaiJson',
});

interface BannerProps {
    langKey: string;
    currentCountryCode: string | null;
    bannerType: string;
    transformContent?: (content: string) => string;
    isHaveCloseButton?: boolean;
}

const Banner = ({
    langKey, currentCountryCode, bannerType, transformContent, isHaveCloseButton
}: BannerProps) => {
    if (!transformContent) {
        transformContent = (content: string) => content;
    }
    const dataByLanguage = useStaticQuery(graphql`
        query HeaderQuery {
            englishJson {
                banner {
                    id {
                        antiScam
                        privacyPolicy 
                    }
                    my {
                        antiScam
                        privacyPolicy 
                    }
                    ph {
                        antiScam
                        privacyPolicy 
                    }
                    sg {
                        antiScam
                        privacyPolicy 
                    }
                    th {
                        antiScam
                        privacyPolicy 
                    }
                    vn {
                        antiScam
                        privacyPolicy 
                    }
                    in {
                        antiScam
                        privacyPolicy 
                    }
                }
            }
            thaiJson {
                banner {
                    id {
                        antiScam
                        privacyPolicy 
                    }
                    my {
                        antiScam
                        privacyPolicy 
                    }
                    ph {
                        antiScam
                        privacyPolicy 
                    }
                    sg {
                        antiScam
                        privacyPolicy 
                    }
                    th {
                        antiScam
                        privacyPolicy 
                    }
                }
            }
        }
    `);

    const [changeBanner, setChangeBanner] = useState(1);
    const bannerData = useMemo(
        () => dataByLanguage[jsonKeyByLangCode[langKey]]?.banner?.[currentCountryCode] || {},
        [langKey, currentCountryCode]
    );

    const isBannerClosed = useMemo(() => {
        if (global.window) {
            return global.window.localStorage.getItem(`banner-${bannerType}`) === 'true';
        }

        return false;
    }, [bannerType, changeBanner]);

    const onBannerClosed = useCallback(() => {
        if (global.window) {
            global.window.localStorage.setItem(`banner-${bannerType}`, 'true');
            setChangeBanner(changeBanner + 1);
        }
    }, [bannerData]);

    return !isBannerClosed && bannerData[bannerType] && (
        <div className="banner">
            <div className="container banner-container">
                <div className="banner-text"
                    dangerouslySetInnerHTML={{__html: transformContent(bannerData[bannerType])}}/>
                {isHaveCloseButton && <Button className="banner-btn" onClick={onBannerClosed}>
                    I Accept
                </Button>}
            </div>
        </div>
    );
};

export default Banner;

import * as React from 'react';
import { CountryDropdownItem } from './country-dropdown-item';
import './country-dropdown.scss';
import Image from '../image/image';


interface CountryDropdownProps {
    activeItemData: {
        image_flag: string;
        label: string;
        value: string;
    };
    items: Array<any>;
    activeCountry: string | string[];
    id?: string;
}

const CountryDropdown = ({
    activeItemData, items, activeCountry,
    id = Date.now().toString(),
}: CountryDropdownProps) => {
    return (
        <div className="dropdown country-dropdown">
            <button
                className="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                id={id}
                aria-label="Toggle country dropdown"
            >
                <div
                    className="dropdown-value"
                >
                    {!!activeItemData.image_flag?.length && (
                        <Image
                            fileName={activeItemData.image_flag}
                            rawPath={`/flags/${activeItemData.image_flag}`}
                            alt={`${activeItemData.image_flag}`}
                            width={26}
                            height={26}
                        />
                    )}
                    <span className="dropdown-item__text">{activeItemData.label}</span>
                </div>
            </button>
            <ul
                className="dropdown-menu dropdown-menu-end"
            >
                {items.map(countryItemData => (
                    <CountryDropdownItem
                        key={countryItemData.value}
                        active={activeCountry === countryItemData.value}
                        {...countryItemData}
                    />
                ))}
            </ul>
        </div>
    );
};

export default CountryDropdown;

import * as React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {graphql, useStaticQuery} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface ImageProps {
    rawPath?: string;
    fileName: string;
    alt?: string;
    className?: string;
    width?: number | string | undefined;
    height?: number | string | undefined;
    // eslint-disable-next-line no-undef
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

const renderImage = (allData, configuration: ImageProps) => {
    const imageData = allData.allFile.nodes.find(img => `${img.name}.${img.extension}` === configuration.fileName);

    if (!imageData || !imageData.childImageSharp || imageData.extension === "svg") {
        return (
            <LazyLoadImage
                className={configuration.className}
                src={configuration.rawPath}
                alt={configuration.alt || configuration.fileName}
                width={configuration.width}
                height={configuration.height}
            />
        );
    }

    const image = getImage(imageData);

    return (
        <GatsbyImage
            className={configuration.className}
            image={image}
            alt={configuration.alt || imageData.name}
        />
    );
};

const Image = (props: ImageProps) => {
    const data = useStaticQuery(
        graphql`
          query ImageQuery {
              allFile {
                nodes {
                  name
                  sourceInstanceName
                  extension
                  relativePath
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
        `);

    return renderImage(data, props);
};

export default Image;

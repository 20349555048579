import {HyperlinkType} from "../../hooks/use-hyperlink";

export const FooterContextKeys = Object.freeze({
    home: 'home',
    careers: 'careers',
    contact: 'contact',
    about: 'about',
    partner: 'partner',
    news: 'news',
    privacy: 'privacy',
    rights: 'rights',
    sign_up: 'sign-up',
    terms: 'terms',
    socials: 'socials',
    address: 'address',
    contactInfo: 'contactInfo',
});

export const footerDefaultContext = Object.freeze({
    [FooterContextKeys.home]: '',
    [FooterContextKeys.careers]: '',
    [FooterContextKeys.partner]: '',
    [FooterContextKeys.contact]: '',
    [FooterContextKeys.about]: '',
    [FooterContextKeys.news]: '',
    [FooterContextKeys.privacy]: '',
    [FooterContextKeys.rights]: '',
    [FooterContextKeys.sign_up]: '',
    [FooterContextKeys.terms]: '',
    [FooterContextKeys.socials]: [],
});

interface footerContactItem {
    title: string;
    type: 'text' | 'links';
    valueItems?: Array<{
        label: string;
        valueByCountry: {
            sg: string;
            ph: string;
            id: string;
            th: string;
            my: string;
            vn: string;
        };
        linkPrefix: HyperlinkType;
    }>;
    valueByCountry?: {
        sg: string;
        ph: string;
        id: string;
        th: string;
        my: string;
        vn: string;
    };
}

export interface FooterProps {
    [FooterContextKeys.home]: string,
    [FooterContextKeys.careers]: string,
    [FooterContextKeys.contact]: string,
    [FooterContextKeys.about]: string,
    [FooterContextKeys.partner]: string,
    [FooterContextKeys.news]: string,
    [FooterContextKeys.privacy]: string,
    [FooterContextKeys.rights]: string,
    [FooterContextKeys.sign_up]: string,
    [FooterContextKeys.terms]: string,
    [FooterContextKeys.socials]: Array<any>,
    [FooterContextKeys.address]: footerContactItem,
    [FooterContextKeys.contactInfo]: footerContactItem,
}

import * as React from 'react';
import classnames from 'classnames';

import './style.scss';

interface Journey {
    year: string | number;
    content: Array<{
        title: string;
        text: string;
    }>
}

interface SectionJourneyProps {
    items: Array<Journey>;
    title: string;
}

const SectionJourney = ({
    items, title,
}: SectionJourneyProps) => {
    const [activeTimelineIndex, setActiveTimelineIndex] = React.useState(0);

    return (
        <section className="section-journey">
            <div className="container">
                <h3 className="section-journey__title">
                    {title}
                </h3>

                <div
                    className="section-journey__timeline timeline-journey"
                    id="timeline-journey"
                >
                    <ul className="timeline-journey__nav">
                        {items.map(({ year }, index) => (
                            <li
                                key={year}
                                className={index === activeTimelineIndex ? 'active' : ''}
                                onClick={() => setActiveTimelineIndex(index)}
                            >
                                <div className="timeline-journey__nav-value">
                                    {year}
                                </div>
                                <div className="timeline-journey__nav-control" />
                            </li>
                        ))}
                    </ul>
                    <div className="timeline-journey__content-container">
                        {items.map(({
                            content,
                            year,
                        }, index) => (
                            <div
                                key={year}
                                className={classnames('timeline-journey__content', {
                                    active: index === activeTimelineIndex,
                                })}
                            >
                                {content.map(({ title, text }, contentIndex) => !!text.length && (
                                    <div
                                        key={contentIndex}
                                        className="timeline-journey__content-item"
                                    >
                                        <div
                                            className="timeline-journey__content-item-title"
                                            dangerouslySetInnerHTML={{ __html: title }}
                                        />
                                        <div
                                            className="timeline-journey__content-item-text"
                                            dangerouslySetInnerHTML={{ __html: text }}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionJourney;

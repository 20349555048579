import { MouseEventHandler, useCallback } from 'react';

export type HyperlinkType = 'mailto' | 'tel';

interface UseHyperlinkArgs  {
    type: HyperlinkType;
    value: string;
}

const useHyperlink = ({ type, value }: UseHyperlinkArgs): [MouseEventHandler] => {
    const setTargetHref = useCallback(
        (event) => event.target.setAttribute('href', `${type}: ${value}`),
        [type, value],
    );
    return [setTargetHref];
};

export default useHyperlink;
import * as React from 'react';
import classnames from 'classnames';

import './styles.scss';

interface ToastProps {
    backgroundColor?: string;
    containerZIndex?: number;
    text?: string;
    getRef?: (ref: HTMLDivElement) => void;
    position?: string;
}

const Toast = ({
    backgroundColor = '#35C04F',
    containerZIndex = 11,
    text = 'Success!',
    getRef,
    position = 'bottom-0 end-0',
}: ToastProps) => {
    const toastRef = React.useCallback(
        ref => getRef?.(ref),
        [getRef]
    );
    return  (
        <div
            className={classnames('position-fixed p-3', position)}
            style={{ zIndex: containerZIndex }}
        >
            <div
                ref={toastRef}
                className="toast align-items-center text-white border-0 p-2"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                style={{ backgroundColor }}
            >
                <div className="d-flex fs-5">
                    <div className="toast-body p-0 ps-1">
                        {text}
                    </div>
                    <button
                        type="button"
                        className="btn-close btn-close-white me-2 m-auto"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                    />
                </div>
            </div>
        </div>
    );
};

export default Toast;

import React, {
    Fragment, ReactNode, useMemo,
} from "react";

import {
    countryDisabledChatBot,
    HeaderContextKeys,
    HeaderProps,
} from "../../context/header/header-context";
import {FooterContextKeys, FooterProps,} from "../../context/footer/footer-context";
import CountryContext from "../../context/country/country-context";
import LanguageContext from "../../context/language/language-context";
import {CustomerSurveyContextProps} from "../../context/customer-survey/customer-survey-context";

import Header from '../header';
import Footer from '../footer';

import CookieConsent from "./cookie-overlay/cookie-consent";
import CommonHelmet from "./common-helmet";
import InitialCountryModal, {} from "./initial-country-modal";

import useCountryConfirmation from "./hooks/use-country-confirmation";
import useCurrentCountry from "./hooks/use-current-country";
import ToastMessage from "./toast-message";

const LazyChatbot = React.lazy(() => import("./chatbot"));
const LazyCustomerSurvey = React.lazy(() => import("./customer-survey"));

interface PageContainerProps {
    children: ReactNode;
    langKey: string;
    commonComponentsData: {
        header: HeaderProps;
        footer: FooterProps;
        customerSurvey: CustomerSurveyContextProps;
    };
    meta?: {
        [key: string]: {
            title: string;
            description: string;
        };
    }
    isInIndexPage?: boolean;
}

const PageContainer = ({
    children, langKey,
    commonComponentsData, meta, isInIndexPage = false,
}: PageContainerProps) => {
    const {currentCountry} = useCurrentCountry();

    const currentMeta = useMemo(
        () => meta?.[currentCountry] ?? {},
        [meta, currentCountry]
    );

    // Country confirmation logic for Singapore
    const {
        confirmInitialCountry
    } = useCountryConfirmation({isInIndexPage, currentCountry});

    return (
        <Fragment>
            <CookieConsent></CookieConsent>
            <CommonHelmet
                currentCountry={currentCountry}
                langKey={langKey}
                {...currentMeta}
            />
            <CountryContext.Provider
                value={{
                    countryCode: currentCountry,
                }}
            >
                <LanguageContext.Provider value={langKey}>
                    <Header
                        {...commonComponentsData.header}
                        {...{
                            [FooterContextKeys.privacy]:
                                commonComponentsData.footer[FooterContextKeys.privacy],
                            [FooterContextKeys.terms]:
                                commonComponentsData.footer[FooterContextKeys.terms],
                            [FooterContextKeys.socials]:
                                commonComponentsData.footer[FooterContextKeys.socials],
                        }}
                    />

                    {children}

                    <Footer {...commonComponentsData.footer} />
                </LanguageContext.Provider>

                <InitialCountryModal
                    currentCountry={currentCountry}
                    confirm={confirmInitialCountry}
                    {...{
                        [HeaderContextKeys.countries_dropdown]: commonComponentsData.header[HeaderContextKeys.countries_dropdown],
                    }}
                />

                <React.Suspense fallback={<div/>}>
                    <LazyCustomerSurvey
                        customerSurveyData={commonComponentsData.customerSurvey}
                        currentCountry={currentCountry}
                        langKey={langKey}
                    />
                </React.Suspense>
                <React.Suspense fallback={<div/>}>
                    {countryDisabledChatBot.includes(currentCountry) ?
                        <ToastMessage
                            message={commonComponentsData.header[HeaderContextKeys.get_started_link].with_message}/> :
                        <LazyChatbot currentCountry={currentCountry}/>
                    }
                </React.Suspense>
            </CountryContext.Provider>

        </Fragment>
    );
};

export default PageContainer;

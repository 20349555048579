import { createContext } from 'react';

import { Countries } from "../header/header-context";

const countriesArr = [...Object.values(Countries)] as const;

export type countryCodeType = typeof countriesArr[number] | '';

interface CountryContextValuesTypes {
    countryCode: countryCodeType;
}

const CountryContext = createContext<CountryContextValuesTypes>({
    countryCode: '',
});

export default CountryContext;
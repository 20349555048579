import React, {useContext, useMemo} from 'react';
import {Link} from 'gatsby';
import without from 'lodash.without';
import classnames from 'classnames';

import {LanguagesKeys, LanguagesLabelsByKey} from '../../context/header/header-context';
import LanguageContext from '../../context/language/language-context';
import urlJoin from "url-join";
import CountryContext from "../../context/country/country-context";

interface LanguageDropdownItemProps {
    // @ts-ignore
    code: LanguagesKeys.en | LanguagesKeys.th | LanguagesKeys.fil | LanguagesKeys.id | LanguagesKeys.ms;
    active: boolean;
}

const DynamicGeneratePage = [
    'news-details',
    'job-details',
];

const LanguageDropdownItem = ({
    code, active,
}: LanguageDropdownItemProps) => {
    const langKey: string = useContext(LanguageContext);
    const country = useContext(CountryContext);

    const pathnameWithoutLangKey = without(global.window?.location.pathname.split('/'), langKey);
    if (pathnameWithoutLangKey && pathnameWithoutLangKey.some(n => DynamicGeneratePage.some(d => d === n))) {
        const langKeyIndex = pathnameWithoutLangKey[pathnameWithoutLangKey.length - 1] === ''
            ? pathnameWithoutLangKey.length - 2
            : pathnameWithoutLangKey.length - 1;
        pathnameWithoutLangKey[langKeyIndex] = `${country.countryCode}${code}`;
    }
    const url = useMemo(
        () => urlJoin(
            global.window?.location.origin,
            code === LanguagesKeys.en ? '' : code,
            pathnameWithoutLangKey.length ? pathnameWithoutLangKey.join('/') : '',
            global.window?.location.search
        ),
        [code]
    );

    return (
        <li>
            <Link
                className={classnames('dropdown-item', {active})}
                to={url}
            >
                {LanguagesLabelsByKey[code]}
            </Link>
        </li>
    );
};

export default LanguageDropdownItem;

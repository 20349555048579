import React, { useContext, useMemo } from "react";
import classnames from "classnames";

import CountryContext from '../../context/country/country-context';
import useHyperlink, { HyperlinkType } from '../../hooks/use-hyperlink';

type Props = {
    label: string;
    valueByCountry: {
        [key: string]: string;
    };
    linkPrefix: HyperlinkType;
    index?: number;
    className?: string;
}

const LinkItem = ({
    linkPrefix, valueByCountry, label,
    index = 0, className,
}: Props) => {
    const { countryCode } = useContext(CountryContext);

    const currentValue = useMemo(
        () => valueByCountry[countryCode],
        [valueByCountry, countryCode]
    );
    const isMultipleValues = useMemo(() => !!currentValue?.includes(','), [currentValue]);

    const [setTargetHref] = useHyperlink({ type: linkPrefix, value: currentValue });

    if (isMultipleValues) {
        const multipleValuesArr = currentValue.split(',');
        return multipleValuesArr.map(
            (value, index) => (
                <LinkItem
                    key={value}
                    index={index}
                    linkPrefix={linkPrefix}
                    label={label}
                    valueByCountry={{ [countryCode]: value }}
                />
            )
        );
    }

    return currentValue && (
        <a
            rel="nofollow"
            onClick={setTargetHref}
            className={classnames('hyperlink', className)}
            href={`${linkPrefix}: ${currentValue}`}
        >
            {(label && valueByCountry[countryCode]?.length &&  !index) ? `${label}:` : ''} {valueByCountry[countryCode]}
        </a>
    );
};

export default LinkItem;

import React, { useState, useCallback } from 'react';
import * as classnames from 'classnames';
import { useDropzone, FileError } from 'react-dropzone';
import { File } from 'buffer';
import isFunction from 'lodash.isfunction';

import './style.scss';

const fileToDataURL = (file) => {
    const reader = new global.FileReader();
    return new global.Promise(function (resolve) {
        reader.onload = function (event) {
            resolve({
                base64: event.target.result,
                name: file.name,
                size: file.size,
            });
        };
        reader.readAsDataURL(file);
    });
};

interface FormAttachmentsProps {
    label?: string;
    attachments: Array<{
        attachment_base64: string;
        attachment_name: string;
    }>;
    setAttachments: Function;
    strings?: {
        addAttachment: string;
        drop: string;
    };
    accept?: { [key: string]: string[] };
    // eslint-disable-next-line no-unused-vars
    validator?: <T extends File>(file: T) => FileError;
    maxFiles?: number;
}

const FormAttachments = ({
    label, attachments, setAttachments,
    strings = { addAttachment: 'Add file', drop: 'or drop files here' },
    accept, validator, maxFiles
}: FormAttachmentsProps) => {
    const [processingAttachments, setProcessingAttachments] = useState(false);
    const onDrop = useCallback(async (acceptedFiles) => {
        setProcessingAttachments(true);
        const files = await global.Promise.all(acceptedFiles.map(fileToDataURL));
        setAttachments(files);
        setProcessingAttachments(false);
    }, [setAttachments]);

    const {
        getRootProps, getInputProps, isDragActive,
        acceptedFiles, fileRejections,
        // @ts-ignore
    } = useDropzone({
        onDrop,
        ...(!!accept?.length && { accept }),
        ...(isFunction(validator) && { validator }),
        ...(maxFiles && { maxFiles }),
        onFileDialogCancel: () => setAttachments([]),
    });

    return (
        <div className="form-attachments">
            <label className="form-label" htmlFor="attachments">
                {label}
                <span className="star">*</span>
            </label>
            <label
                className={classnames(
                    'form-label',
                    'form-label--visual',
                    {
                        dragging: isDragActive,
                    },
                )}
                htmlFor="attachments"
                {...getRootProps()}
            >
                {attachments?.length
                    ? (
                        attachments?.length
                            && [
                                // @ts-ignore
                                ...acceptedFiles.map(({ path }) => path),
                            ].map((name, index) => (
                                <span key={name}>
                                    {index > 0 && <>, &nbsp;</>}
                                    <span key={name}>{name}</span>
                                </span>
                            ))
                    )
                    : (processingAttachments
                        ? (
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <React.Fragment>
                                <span>{strings.addAttachment}</span>
                                {'\u00A0'}
                                <span className="grey">{strings.drop}</span>
                            </React.Fragment>
                        )
                    )
                }
                <input
                    {...getInputProps()}
                />
            </label>
            {!!fileRejections?.length && fileRejections.map(
                // @ts-ignore
                ({ file: { path }, errors }, index) => (
                    <>
                        {index > 0 && <>, &nbsp;</>}
                        <span
                            key={path}
                            className="error"
                        >
                            <b>{path}:</b>
                            &nbsp;
                            {errors[0]?.message}
                        </span>
                    </>
                )
            )}
        </div>
    );
};

export default FormAttachments;

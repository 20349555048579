import React, { useContext  } from 'react';

import { LanguagesByCountry, LanguagesLabelsByKey } from '../../context/header/header-context';
import CountryContext from '../../context/country/country-context';
import LanguageContext from '../../context/language/language-context';

import LanguageDropdownItem from './language-dropdown-item';

const LanguageDropdownShort = () => {
    const { countryCode } = useContext(CountryContext);
    const langKey: string = useContext(LanguageContext);

    return (
        <div className="dropdown language-dropdown short">
            <button
                className="button language-dropdown__toggle dropdown-toggle"
                type="button"
                id="language-dropdown-trigger"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span className="dropdown-value">{LanguagesLabelsByKey[langKey]}</span>
            </button>
            <ul
                className="language-dropdown__menu dropdown-menu"
                aria-labelledby="language-dropdown-trigger"
            >
                {LanguagesByCountry[countryCode]?.map(
                    languageCode => (
                        <LanguageDropdownItem
                            key={languageCode}
                            code={languageCode}
                            active={languageCode === langKey}
                        />
                    )
                )}
            </ul>
        </div>
    );
};

export default LanguageDropdownShort;

import React, {useCallback, useState} from "react";

import './toast-message.scss';
import useCurrentCountry from "./hooks/use-current-country";

interface ToastMessageProps {
    message: string;
}

const ToastMessage = (data: ToastMessageProps) => {
    const [surveyTriggerVisible, setSurveyTriggerVisible] = useState(true);
    const closeSurveyTrigger = useCallback(
        () => {
            setSurveyTriggerVisible(false);
        },
        [setSurveyTriggerVisible]
    );

    return <>
        {surveyTriggerVisible && <div className="toast-box-container toast-message">
            <div className="toast-box">
                <button
                    type="button"
                    className="survey-trigger-box__close-btn btn-close btn-close-black"
                    aria-label="Close"
                    onClick={closeSurveyTrigger}
                />

                <div className="survey-trigger-box__content">
                    {data.message}
                </div>
            </div>
        </div>}
    </>;
};

export default ToastMessage;
import React, { useContext } from 'react';

import {
    LanguagesByCountry, LanguagesLabelsByKey,
} from "../../context/header/header-context";
import CountryContext from "../../context/country/country-context";
import LanguageContext from "../../context/language/language-context";

import LanguageDropdownItem from "./language-dropdown-item";

const LanguageDropdown = () => {
    const { countryCode } = useContext(CountryContext);
    const langKey: string = useContext(LanguageContext);

    return (
        <div className="dropdown language-dropdown white">
            <button
                className="button language-dropdown__toggle dropdown-toggle"
                type="button"
                id="language-dropdown-trigger"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span className="dropdown-value">{LanguagesLabelsByKey[langKey]}</span>
            </button>
            <ul
                className="language-dropdown__menu dropdown-menu"
                aria-labelledby="language-dropdown-trigger"
            >
                {LanguagesByCountry[countryCode]?.map(
                    languageCode => (
                        <LanguageDropdownItem
                            key={languageCode}
                            code={languageCode}
                            active={langKey === languageCode}
                        />
                    )
                )}
            </ul>
        </div>
    );
};

export default LanguageDropdown;

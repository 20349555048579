import {useEffect, useState} from "react";

const localStorageKey = 'cookie-consent';

const useCookieOverlay = () => {
    const [showCookieOverlay, setShowCookieOverlay] = useState<boolean>(false);
    useEffect(() => {
        const isAccepted = global.window.localStorage.getItem(localStorageKey);
        if (isAccepted) {
            setShowCookieOverlay(false);
        } else {
            setShowCookieOverlay(true);
        }

    }, []);

    return {showCookieOverlay, setShowCookieOverlay};
};

export default useCookieOverlay;
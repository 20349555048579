import React, { useState, useEffect } from 'react';
import urlJoin from 'url-join';
import classNames from 'classnames';

import Config from '../../../config/config.json';

import { Countries, CountryNamesByCode } from '../../context/header/header-context';
import Image from '../image/image';
const getURL = ({ location, countryCode }): string => {
    if (!location) return '';

    const host = location.host;
    if (host?.length) {
        const port = location.port;
        
        const onTest = Object.values(Config.hostsByCountry).some(testHost => host === testHost);

        if (port.length) { // Dev local logic
            return `${location.protocol}//${location.hostname}:${Config.portsByCountry[countryCode]}`;
        } else if (onTest) { // Dev logic
            return urlJoin('https://', Config.hostsByCountry[countryCode]);
        }

        if(countryCode == Countries.in) {
            return 'https://www.collectius.in';
        }

        // Production logic
        return urlJoin(
            'https://',
            `${countryCode === Countries.sg ? 'www.' : `${CountryNamesByCode[countryCode]}.`}${domainNameDefault}`
        );
    }
    
    return '';
};

const domainNameDefault = 'collectius.com';
interface CountryDropdownItemProps {
    value: string;
    active: boolean;
    image_flag: string;
    label: string;
}

export const CountryDropdownItem = ({
    value, active, image_flag,
    label,
}: CountryDropdownItemProps) => {
    const [url, setURL] = useState<string>('');

    useEffect(
        () => {            
            if (!url) {                
                const urlToSet = getURL({ location: global.window?.location, countryCode: value });
                setURL(urlToSet);
            }
        },
        [global.window?.location, url, value]
    );

    return (
        <li>
            <a
                href={url}
                className={classNames(
                    'dropdown-item',
                    { active }
                )}
            >
                <Image fileName={image_flag} rawPath={`/flags/${image_flag}`} alt={`${image_flag}`} />
                <span className="dropdown-item__text">{label}</span>
            </a>
        </li>
    );
};

import * as React from 'react';
import * as classNames from 'classnames';

import LinkComponent from '../link';

import './style.scss';
import Image from "../image/image";

interface FlipCardProps {
    backgroundColor?: string;
    label: string;
    labelBack: string;
    description: string;
    link: string;
    item_link: string;
    illustration: string;
}

const FlipCard = ({
    backgroundColor, label, labelBack,
    description, link, item_link,
    illustration,
}: FlipCardProps) => {
    return (
        <div className={classNames(
            'section-positions__item', {
                [backgroundColor]: !!backgroundColor
            }
        )}>
            <div className="section-positions__item-front">
                <div
                    className="section-positions__item-label"
                    dangerouslySetInnerHTML={{__html: label}}
                />
                <div className="section-positions__item-illustration">
                    <Image fileName={`${illustration}.svg`} rawPath={`/positions/${illustration}.svg`}></Image>
                </div>
            </div>
            <div className="section-positions__item-back">
                <div className="section-positions__item-label">
                    {labelBack}
                </div>
                <div className="section-positions__item-descr" dangerouslySetInnerHTML={{__html: description}}>
                </div>
                <LinkComponent
                    className="button section-positions__item-link"
                    to={link}
                >
                    {item_link}
                </LinkComponent>
            </div>
        </div>
    );
};

export default FlipCard;

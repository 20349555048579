import React from "react";
import useCookieOverlayHook from "./useCookieOverlayHook";

import './style.scss';

const CookieConsent = () => {
    const {showCookieOverlay, setShowCookieOverlay} = useCookieOverlayHook();
    const hideCookieConsent = () => {
        setShowCookieOverlay(false);
        global.window.localStorage.setItem('cookie-consent', 'true');
    };

    return showCookieOverlay && (
        <>
            <div className={'cookie-overlay'}></div>
            <div className={'cookie-consent'}>
                <div className="cookie-consent-container">
                    <div className="text">
                        <p>
                            This site uses Cookies for analytics and personalized content. By using this website, you
                            accept
                            the use of Cookies. Please refer to our website privacy policy for more detailed
                            information
                            &nbsp;
                            <a
                                className="text"
                                href="/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </p>
                    </div>
                    <a className="accept-button" onClick={hideCookieConsent}>
                        I accept
                    </a>
                </div>
            </div>
        </>
    );
};

export default CookieConsent;
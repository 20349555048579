const dayjs = require('dayjs');

const parseStringToDate = (date) => {
    if (date) {
        const dateParts = date.split('-');
        return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    }
    return null;
};

const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

const replaceHtmlContent = (rawHtmlContent, data) => {
    if (!rawHtmlContent)
        return rawHtmlContent;

    Object.keys(data).forEach(key => {
        rawHtmlContent = rawHtmlContent.split(`{{${key}}}`).join(data[key]);
    });

    return rawHtmlContent;
};

const getFormattedDate = (date, format = 'DD MMM YYYY') => date ? dayjs(date).format(format) : null;

module.exports = {
    parseStringToDate,
    capitalize,
    replaceHtmlContent,
    getFormattedDate
};
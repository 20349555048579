import React, {
    useCallback, useState, useContext,
    useMemo,
} from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classnames from 'classnames';

import Config from '../../../config/config.json';

import { LanguageDropdown, LinkComponent } from '../../components';
import {
    Countries, HeaderProps,
    HeaderContextKeys, RegistrationLinkByCountry, countryDisabledChatBot,
} from '../../context/header/header-context';
import { FooterContextKeys } from '../../context/footer/footer-context';
import CountryContext from '../../context/country/country-context';
import LanguageContext from '../../context/language/language-context';

import Banner from '../banner';

import HeaderBurger from './header-burger';

import HeaderCountryDropdown from './components/header-country-dropdown';
import {parseStringToDate, replaceHtmlContent} from '../../utils/common-helpers';

import './style.scss';

interface HeaderPropsExtended extends HeaderProps {
    [FooterContextKeys.privacy]: string;
    [FooterContextKeys.terms]: string;
    [FooterContextKeys.socials]: Array<any>,
}

const Header = ({
    [HeaderContextKeys.countries_dropdown]: countries_dropdown,
    [HeaderContextKeys.about_dropdown]: about_dropdown,
    [HeaderContextKeys.media_dropdown]: media_dropdown,
    [HeaderContextKeys.get_started_link]: get_started_link,
    [HeaderContextKeys.home_link]: home_link,
    [HeaderContextKeys.pay_off_dropdown]: pay_off_dropdown,
    [FooterContextKeys.privacy]: privacy,
    [FooterContextKeys.terms]: terms,
    [FooterContextKeys.socials]: socials,
}: HeaderPropsExtended) => {
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const toggleBurger = useCallback(
        () => setIsBurgerOpen((currentState) => !currentState),
        [],
    );

    const {countryCode} = useContext(CountryContext);
    const langKey: string = useContext(LanguageContext);

    const shouldShowBanner = useMemo(
        () => {
            return countryCode === Countries.th;
        },
        [countryCode]
    );
    const customerPortalClassName = classnames('button main-header__btn-start', {
        'btn-disable': countryDisabledChatBot.includes(countryCode),
    });
    const shouldShowPrivacyPolicy = useMemo(() => {
        const startDate = parseStringToDate(Config.privacyPolicy.startDate);
        const endDate = parseStringToDate(Config.privacyPolicy.endDate);
        if (startDate && endDate) {
            return startDate <= new Date() && endDate >= new Date();
        }
        return false;
    }, [countryCode]);

    return (
        <React.Fragment>
            <header className={classnames('main-header', {withBanner: shouldShowBanner || shouldShowPrivacyPolicy})}>
                {shouldShowBanner && (
                    <Banner
                        langKey={langKey}
                        currentCountryCode={countryCode}
                        bannerType={'antiScam'}
                    />
                )}
                {shouldShowBanner && shouldShowPrivacyPolicy && (<hr style={{margin: 0, padding: 0}}></hr>)}
                {shouldShowPrivacyPolicy && (
                    <Banner
                        langKey={langKey}
                        currentCountryCode={countryCode}
                        bannerType={'privacyPolicy'}
                        isHaveCloseButton={true}
                        transformContent={(content: string) => replaceHtmlContent(content, {startDate: Config.privacyPolicy.startDate})}
                    />
                )}
                <div
                    className={classnames('container main-header__content-container', {'pt-3': shouldShowBanner || shouldShowPrivacyPolicy})}>
                    <div className="main-header__group">
                        <LinkComponent className="main-header__link" to="/">
                            <StaticImage
                                src="../../../static/logos/collectius-logo.svg"
                                alt="Collectius Logo"
                                className="me-4"
                                width={138}
                                height={27}
                            />
                        </LinkComponent>

                        <HeaderCountryDropdown
                            {...{
                                [HeaderContextKeys.countries_dropdown]: countries_dropdown,
                            }}
                        />
                    </div>

                    <div className="main-header__group main-header__group--right">
                        <LinkComponent className="main-header__link" to="/">{home_link.label}</LinkComponent>

                        {!!about_dropdown.items && (
                            <div className="dropdown dropdown-links">
                                <button
                                    className="button language-dropdown__toggle dropdown-toggle"
                                    type="button"
                                    id="about-dropdown-trigger"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    aria-label={about_dropdown.label}
                                >
                                    <span className="dropdown-value">{about_dropdown.label}</span>
                                </button>
                                <ul
                                    className="language-dropdown__menu dropdown-menu"
                                    aria-labelledby="about-dropdown-trigger"
                                >
                                    <li>
                                        <LinkComponent
                                            className="dropdown-item"
                                            to="/about"
                                            activeClassName="active"
                                        >
                                            {about_dropdown.items['about-us']}
                                        </LinkComponent>
                                    </li>
                                    <li>
                                        <LinkComponent className="dropdown-item" to="/careers">
                                            {about_dropdown.items.careers}
                                        </LinkComponent>
                                    </li>
                                    <li>
                                        <LinkComponent className="dropdown-item" to="/partner-form">
                                            {about_dropdown.items.partner}
                                        </LinkComponent>
                                    </li>
                                </ul>
                            </div>
                        )}

                        <LinkComponent className="main-header__link" to="/contact-us">
                            {pay_off_dropdown.items.contact}
                        </LinkComponent>

                        {!!media_dropdown.items && (
                            <div className="dropdown dropdown-links">
                                <button
                                    className="button language-dropdown__toggle dropdown-toggle"
                                    type="button"
                                    id="media-dropdown-trigger"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    aria-label="media_dropdown.label"
                                >
                                    <span className="dropdown-value">{media_dropdown.label}</span>
                                </button>
                                <ul
                                    className="language-dropdown__menu dropdown-menu"
                                    aria-labelledby="media-dropdown-trigger"
                                >
                                    <li>
                                        <LinkComponent
                                            className="dropdown-item"
                                            to="/news"
                                            activeClassName="active"
                                        >
                                            {media_dropdown.items.news}
                                        </LinkComponent>
                                    </li>
                                    <li>
                                        <LinkComponent className="dropdown-item" to="/articles">
                                            {media_dropdown.items.articles}
                                        </LinkComponent>
                                    </li>
                                </ul>
                            </div>
                        )}

                        {countryCode && (
                            <LanguageDropdown/>
                        )}

                        <a
                            className={customerPortalClassName}
                            href={countryCode ? RegistrationLinkByCountry[countryCode] : '#'}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={get_started_link.label}
                        >
                            {get_started_link.label}
                        </a>
                    </div>
                    <button
                        className="main-header__btn-burger-toggle btn-burger-toggle"
                        onClick={toggleBurger}
                        aria-label="Open menu"
                    >
                        <div className="btn-burger-toggle__line"/>
                        <div className="btn-burger-toggle__line"/>
                        <div className="btn-burger-toggle__line"/>
                    </button>
                </div>
            </header>

            <HeaderBurger
                toggleBurger={toggleBurger}
                isOpen={isBurgerOpen}
                {...{
                    [HeaderContextKeys.countries_dropdown]: countries_dropdown,
                    [HeaderContextKeys.about_dropdown]: about_dropdown,
                    [HeaderContextKeys.media_dropdown]: media_dropdown,
                    [HeaderContextKeys.get_started_link]: get_started_link,
                    [HeaderContextKeys.home_link]: home_link,
                    [HeaderContextKeys.pay_off_dropdown]: pay_off_dropdown,
                    [FooterContextKeys.privacy]: privacy,
                    [FooterContextKeys.terms]: terms,
                    [FooterContextKeys.socials]: socials,
                }}
            />
        </React.Fragment>
    );
};

export default Header;

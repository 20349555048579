import {useEffect, useState} from "react";
import without from "lodash.without";

import Config from '../../../../config/config.json';

import {countryCodeType} from "../../../context/country/country-context";
import {CountryCodesByName, defaultCountry} from "../../../context/header/header-context";

function findKey(obj: {
    [key: string]: any
// eslint-disable-next-line no-unused-vars
}, predicate: (value: any) => boolean) {
    return Object.keys(obj).find((key) => predicate(obj[key]));
}

const CountriesTestHostsArr = Object.values(Config.hostsByCountry);

const useCurrentCountry = () => {
    const [currentCountry, setCurrentCountry] = useState<countryCodeType>("");
    useEffect(
        () => {
            const host = global.window?.location.host;
            if (host?.length) {
                const port = global.window?.location.port;

                const onTest = CountriesTestHostsArr.some(
                    testHost => host === testHost
                );

                if (!currentCountry) {
                    if (host.endsWith("collectius.in")) {
                        setCurrentCountry("in");
                    } else if (port.length) { // Dev local logic
                        const currentCountryCode = findKey(
                            Config.portsByCountry,
                            testPort => port === testPort
                        );
                        // @ts-ignore
                        setCurrentCountry(currentCountryCode);
                    } else if (onTest) { // Dev logic
                        const currentCountryCode = findKey(
                            Config.hostsByCountry,
                            testHost => host === testHost
                        );
                        // @ts-ignore
                        setCurrentCountry(currentCountryCode);
                    } else { // Production logic
                        const currentCountryName = without(
                            host.split("."),
                            "www", "collectius", "com"
                        )[0];
                        const shouldUseDefaultCountry = !Object.keys(CountryCodesByName).some(
                            (countryName) => countryName === currentCountryName
                        );
                        const currentCountryCode = shouldUseDefaultCountry
                            ? defaultCountry
                            : CountryCodesByName[currentCountryName];
                        setCurrentCountry(currentCountryCode);
                    }
                }
            }
        },
        []
    );

    return {
        currentCountry,
    };
};

export default useCurrentCountry;

import * as React from 'react';

import { CountryDropdown } from '../../../components';
import { HeaderContextKeys } from '../../../context/header/header-context';

import useHeaderCountryDropdown from '../hooks/use-header-country-dropdown';

interface HeaderCountryDropdownProps {
    label?: string;
    [HeaderContextKeys.countries_dropdown]: { [key: string]: any},
}

const HeaderCountryDropdown = ({
    label,
    [HeaderContextKeys.countries_dropdown]: countries_dropdown
}: HeaderCountryDropdownProps) => {
    const [items, activeCountry, activeItemData] = useHeaderCountryDropdown({
        [HeaderContextKeys.countries_dropdown]: countries_dropdown
    });

    return !!activeItemData.value && (
        <div>
            {label}
            <CountryDropdown
                id={label}
                activeItemData={activeItemData}
                activeCountry={activeCountry}
                items={items}
            />
        </div>
    );
};

export default HeaderCountryDropdown;

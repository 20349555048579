import React, {
    useContext, useMemo, ReactElement,
} from 'react';
import { Link } from 'gatsby';
import urlJoin from 'url-join';

import LanguageContext from '../../context/language/language-context';
import { LanguagesKeys } from '../../context/header/header-context';

interface LinkComponentProps {
    className: string;
    children: string | ReactElement | Array<ReactElement | string>;
    to: string;
    activeClassName?: string;
}

const LinkComponent = ({
    children, className, to,
    ...rest
}: LinkComponentProps) => {
    const langKey = useContext(LanguageContext);

    const langKeyToUse = useMemo(
        () => langKey === LanguagesKeys.en ? '/' : `/${langKey}`,
        [langKey]
    );

    const url = useMemo(
        () => urlJoin(langKeyToUse, to),
        [langKeyToUse, to]
    );

    return (
        <Link
            className={className}
            to={url}
            {...rest}
        >
            {children}
        </Link>
    );
};

export default LinkComponent;

import {useCallback, useEffect, useState} from "react";
import isNull from "lodash.isnull";
import Modal from "bootstrap/js/dist/modal";

import {Countries} from "../../../context/header/header-context";
import {initialCountryConfirmedUntilKey, initialCountryConfirmModalId} from "../initial-country-modal";
import {countryCodeType} from "../../../context/country/country-context";

const useCountryConfirmation = ({
    isInIndexPage, currentCountry,
}: {
    isInIndexPage: boolean;
    currentCountry: countryCodeType;
}) => {
    const [initialCountryConfirmModal, setInitialCountryConfirmModal] = useState(null);
    useEffect(
        () => {
            if (currentCountry === Countries.sg && isInIndexPage) {
                const initialCountryConfirmedUntil = global.window.localStorage.getItem(initialCountryConfirmedUntilKey);
                const currentDate = new global.window.Date().toString();
                const countryConfirmationExpired = global.window.Date.parse(initialCountryConfirmedUntil) - global.window.Date.parse(currentDate) <= 100;

                if (isNull(initialCountryConfirmedUntil) || countryConfirmationExpired) {
                    setInitialCountryConfirmModal(
                        new Modal(
                            global.document.getElementById(initialCountryConfirmModalId),
                            {
                                backdrop: false,
                                keyboard: false,
                            }
                        )
                    );
                }
            } else if (currentCountry && currentCountry !== Countries.sg) {
                global.window.localStorage.removeItem(initialCountryConfirmedUntilKey);
            }
        },
        [currentCountry, isInIndexPage]
    );
    useEffect(
        () => {
            if (!isNull(initialCountryConfirmModal)) {
                initialCountryConfirmModal.show();
            }
        },
        [initialCountryConfirmModal]
    );
    const confirmInitialCountry = useCallback(
        () => {
            if (global.window) {
                const now = new global.window.Date().getTime();
                const weekFromNow = new global.window.Date(now + 7 * 24 * 60 * 60 * 1000);
                global.window.localStorage[initialCountryConfirmedUntilKey] = weekFromNow;
            }

            initialCountryConfirmModal.toggle();
        },
        [initialCountryConfirmModal, currentCountry]
    );

    return {
        confirmInitialCountry,
    };
};

export default useCountryConfirmation;

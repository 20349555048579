import * as React from 'react';
import classnames from 'classnames';

import LinkComponent from '../link';

import './style.scss';

interface ButtonProps {
    disabled?: boolean;
    hasLoader?: boolean;
    children: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | (string | React.ReactElement<any, string | React.JSXElementConstructor<any>>)[];
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    link?: {
        external?: boolean;
        to: string;
    },
    // eslint-disable-next-line no-undef
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

const Spinner = () => (
    <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
);

const Button = ({
    disabled = false,
    hasLoader = false,
    children,
    className,
    type,
    link,
    onClick,
}: ButtonProps) => {
    const btnClassName = React.useMemo(
        () => classnames('button', {
            [className]: !!className,
            disabled: !!disabled,
        }),
        [className, disabled]
    );

    if (link?.to?.length && link?.external) {
        return (
            <a
                className={btnClassName}
                href={link.to}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );
    }
    if (link?.to?.length && !link?.external) {
        return (
            <LinkComponent
                className={btnClassName}
                to={link.to}
                {...(onClick && { onClick })}
            >
                {children}
            </LinkComponent>
        );
    }

    return (
        <button
            className={btnClassName}
            {...(type && { type })}
            disabled={disabled}
            {...(onClick && { onClick })}
        >
            {hasLoader ? <Spinner /> : children}
        </button>
    );
};

export default Button;

import React, {useMemo, useCallback, useState} from 'react';
import isFunction from 'lodash.isfunction';
import classnames from 'classnames';

import './form-input.scss';

interface FormInputProps {
    label?: string;
    required?: boolean;
    type?: string;
    disabled?: boolean;
    placeholder?:string;
    multiline?: boolean;
    value?: string;
    onChange?: React.ChangeEventHandler;
    errorText?: string;
}

const FormInput = ({
    required = false,
    disabled = false,
    label,
    type = 'text',
    placeholder,
    multiline = false,
    value,
    onChange,
    errorText,
}: FormInputProps, ref) => {
    const [isDirty, setIsDirty] = useState(false);
    const inputClasses = useMemo(
        () => classnames('form-control', { error: isDirty && errorText?.length }),
        [errorText]
    );

    const handleChange = useCallback(
        (event) => {
            setIsDirty(true);
            if (isFunction(onChange)) {
                onChange(event);
            }
        },
        [onChange]
    );
    return (
        <div className="input-container">
            {label && (
                <label className="form-label">
                    {label}
                    <span className="star">{required ? '*' : ''}</span>
                </label>
            )}

            {multiline
                ? (
                    <textarea
                        ref={ref}
                        className={inputClasses}
                        placeholder={placeholder}
                        disabled={disabled}
                        required={required}
                        onChange={handleChange}
                        {...(value?.length && { value })}
                    />
                )
                : (
                    <input
                        ref={ref}
                        className={inputClasses}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled}
                        required={required}
                        onChange={handleChange}
                        value={value}
                    />
                )
            }
            {isDirty && !!errorText?.length && (
                <span className="error">{errorText}</span>
            )}
        </div>
    );
};

export default React.forwardRef(FormInput);

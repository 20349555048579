import React, { useCallback } from 'react';
import capitalize from 'lodash.capitalize';

import { CountryNamesByCode, HeaderContextKeys } from '../../context/header/header-context';
import { Button } from '../../components';
import HeaderCountryDropdown from '../header/components/header-country-dropdown';

import './initial-country-modal.scss';

export const initialCountryConfirmedUntilKey = 'initialCountryConfirmedUntil';
export const initialCountryConfirmModalId = 'initialCountryConfirmModalId';

interface InitialCountryModalProps {
    currentCountry: string;
    confirm: Function;
    [HeaderContextKeys.countries_dropdown]: { [key: string]: any},
}

const InitialCountryModal = ({
    currentCountry, confirm,
    [HeaderContextKeys.countries_dropdown]: countries_dropdown,
}: InitialCountryModalProps) => {
    const handleConfirm = useCallback(
        () => {
            confirm();
        },
        [confirm]
    );

    return (
        <div
            className="modal"
            id={initialCountryConfirmModalId}
        >
            <div className="initial-country-modal modal-dialog modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-body">
                        <h3>
                            Entering {capitalize(CountryNamesByCode[currentCountry])}
                        </h3>

                        <HeaderCountryDropdown
                            label="Change country"
                            {...{
                                [HeaderContextKeys.countries_dropdown]: countries_dropdown,
                            }}
                        />

                        <Button
                            className="initial-country-modal__confirm-btn"
                            onClick={handleConfirm}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InitialCountryModal;
